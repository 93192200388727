import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Icon } from '@/components/general';
import style from './filter.module.scss';

const BrowseVehiclesSidebarFilter = props => {
  const { children, label, collapse = false, disabled = false } = props;
  const cx = classNames.bind(style);

  const [isCollapsed, setCollapse] = useState(!disabled);

  useEffect(() => {
    setCollapse(!disabled);
  }, [disabled]);

  return (
    <div className={cx(style.filter, { disabled })}>
      <div className={style.header} onClick={() => collapse && setCollapse(!isCollapsed)}>
        <div className={style.label} style={{ cursor: `${collapse ? 'pointer' : 'default'}` }}>
          {label}
        </div>
        {collapse && (
          <div>
            <Icon icon={isCollapsed ? 'arrow-down' : 'arrow-up'} size="24" color="#003343" />
          </div>
        )}
      </div>
      <div className={cx(style.content, { collapse: !isCollapsed })}>{children}</div>
    </div>
  );
};

export default BrowseVehiclesSidebarFilter;

import React from 'react';
import cx from 'classnames';
import VehicleItem from '@/components/general/VehicleItem';
import style from './current-vehicle.module.scss';

const CurrentVehicle = props => {
  const { data, isSwapVehicle } = props;

  return (
    <div className={style.container}>
      <VehicleItem
        data={data}
        isSwapVehicle={isSwapVehicle}
        isSwapRoute={isSwapVehicle}
        currentVehicleId={data?.id}
      />
      <div className={cx({ [style.overlay]: data?.status === 'unpublished' })}>
        <div className={style.text}>
          You cannot select this as it is the same vehicle you currently have, please try a
          different vehicle.
        </div>
      </div>
    </div>
  );
};

export default CurrentVehicle;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { DropDown, Icon } from '@/components/general';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import style from './browse-vehicles-header.module.scss';

const cx = classNames.bind(style);

const BrowseVehiclesHeader = props => {
  const {
    filters,
    onChange,
    metaData,
    allCarMake,
    allCarModel,
    onShowFilters,
    colors,
    isSwapVehicle,
  } = props;
  const [tags, setTags] = useState([]);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const isTablet = useBreakpoint('tablet');

  const sortOptions = [
    { value: 'ASC', label: 'Price low to high', sortCol: 'pricing' },
    { value: 'DESC', label: 'Price high to low', sortCol: 'pricing' },
    { value: 'ASC', label: 'Make (A-Z)', sortCol: 'carMake' },
    { value: 'ASC', label: 'Model (A-Z)', sortCol: 'carModel' },
  ];

  const onRemove = tag => {
    onChange(tag.type, tag.id);
  };

  const onSortChange = e => {
    onChange({ sortOperation: e.value, sortCol: e.sortCol });
  };

  const mapMetaData = (arr, key, ids) => {
    const obj = arr.filter(obj1 => {
      return ids.some(id => {
        if (obj1.id === id) {
          obj1.type = key;
          return obj1;
        }
        return null;
      });
    });
    return obj;
  };

  const mapCarMakeModel = (arr, key, ids) => {
    const arrFilter = arr.filter(obj => {
      return ids.some(id => {
        if (obj.value === id) {
          obj.id = id;
          obj.name = obj.label;
          obj.type = key;
          // TODO
          // delete obj.value;
          // delete obj.label;
          return obj;
        }
        return null;
      });
    });
    return arrFilter;
  };

  const populateTags = () => {
    let tmpTags = [];
    Object.keys(filters).forEach(key => {
      switch (key) {
        case 'carTypeId':
          if (filters[key]?.length)
            tmpTags =
              metaData.carType && tmpTags?.concat(mapMetaData(metaData.carType, key, filters[key]));
          break;
        case 'vehicleTypeId':
          if (filters[key]?.length)
            tmpTags =
              metaData.vehicleType &&
              tmpTags?.concat(mapMetaData(metaData.vehicleType, key, filters[key]));
          break;
        case 'ulezCompliant':
          if (filters[key] === 'yes')
            tmpTags = tmpTags?.concat([
              { id: 'yes', name: 'ULEZ Compliant', type: 'ulezCompliant' },
            ]);
          break;
        case 'carMakeId':
          if (filters[key]?.length)
            tmpTags = tmpTags?.concat(mapCarMakeModel(allCarMake, key, filters[key]));
          break;
        case 'carModelId':
          if (filters[key]?.length)
            tmpTags = tmpTags?.concat(mapCarMakeModel(allCarModel, key, filters[key]));
          break;
        case 'fuelTypeId':
          if (filters[key]?.length)
            tmpTags =
              metaData.fuelType &&
              tmpTags?.concat(mapMetaData(metaData.fuelType, key, filters[key]));
          break;
        case 'doorId':
          if (filters[key]?.length)
            tmpTags =
              metaData.doorType &&
              tmpTags?.concat(mapMetaData(metaData.doorType, key, filters[key]));
          break;
        case 'gearboxId':
          if (filters[key]?.length)
            tmpTags =
              metaData.gearbox && tmpTags?.concat(mapMetaData(metaData.gearbox, key, filters[key]));
          break;
        case 'selectedColor':
          if (filters[key]?.length)
            tmpTags =
              colors &&
              tmpTags?.concat(
                mapMetaData(
                  colors.map(data => ({ ...data, id: data.value, name: data.label })),
                  key,
                  filters[key],
                ),
              ); // mapColoraData(filters[key]));
          break;
        default:
          break;
      }
    });
    setTags(tmpTags);
  };

  useEffect(() => {
    if (metaData && filters) populateTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, metaData]);

  useEffect(() => {
    // Control position of Browse Vehicle Header when navbar is visible/hidden when scrolling
    const handleHeaderPos = () => {
      if (typeof window !== 'undefined') {
        const currentScrollPos = window.scrollY;
        setIsNavbarVisible(currentScrollPos < 50 ? true : prevScrollPos > currentScrollPos);
        setPrevScrollPos(currentScrollPos);
      }
    };

    if (isTablet) window.addEventListener('scroll', handleHeaderPos);
    return () => window.removeEventListener('scroll', handleHeaderPos);
  }, [prevScrollPos, isTablet]);

  return (
    <div
      className={cx(style.header, { [style.swapVehicleHeader]: isSwapVehicle })}
      // inline style to control distance of header on mobile relative to the navbar when it's opened
      style={{ top: isTablet && (isNavbarVisible ? '80px' : '0px') }}
    >
      {tags?.length > 0 && (
        <ul className={style.tags}>
          {tags?.map(tag => (
            <li key={tag.type + tag.id} onClick={() => onRemove(tag)}>
              {tag.name}
              <Icon icon="cancel" size={20} color="#001E78" />
            </li>
          ))}
        </ul>
      )}
      <div className={style['header-filter']}>
        <div className={style['sort-by']}>
          <div>Sort by</div>
          <DropDown
            name="sortOperation"
            options={sortOptions}
            value={sortOptions?.find(
              x => x.value === filters?.sortOperation && x.sortCol === filters?.sortCol,
            )}
            selectedValue={sortOptions?.find(
              x => x.value === filters?.sortOperation && x.sortCol === filters?.sortCol,
            )}
            onChange={onSortChange}
            className={style['dropdown-simple']}
          />
        </div>
        <div className={style['filter-button']} onClick={onShowFilters}>
          <Icon icon="filter" size={20} color={style.brandColor} />
          <p className={style['button-content']}>Filters</p>
        </div>
      </div>
    </div>
  );
};

export default BrowseVehiclesHeader;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './list-color.module.scss';

const ListColor = props => {
  const { options, selected, onChange } = props;
  const cx = classNames.bind(style);

  const [localOptions, setOptions] = useState(options);
  const [showMore, setShowMore] = useState(false);

  const isSelected = id => {
    return selected?.includes(id);
  };

  useEffect(() => {
    setOptions(options);
    if (options && options?.length > 5) setShowMore(true);
  }, [options]);

  return (
    <div className={style['search-checkboxes']}>
      <div className={style.options}>
        {localOptions?.map((option, index) =>
          (showMore && index < 3) || !showMore ? (
            <div key={`color-${index}`} className={style['color-container']}>
              <div
                className={
                  isSelected(option.value)
                    ? cx(style['info-color-container'], style['container-border'])
                    : style['info-color-container']
                }
              >
                <div
                  className={style['info-color']}
                  style={{ backgroundColor: option.colorCode }}
                  onClick={() => onChange(option.value)}
                ></div>
              </div>
              <p className={style.label}>{option.label}</p>
            </div>
          ) : null,
        )}
        {localOptions?.length > 3 ? (
          <p className={style.showMore} onClick={() => setShowMore(prevState => !prevState)}>
            {showMore ? 'Show more' : 'Show less'}
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ListColor;

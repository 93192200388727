import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import {
  BubbleListing,
  InputNumberField,
  LinkIcon,
  SwitchButton,
  Button,
} from '@/components/general';
import SearchCheckboxes from '@/components/general/SearchCheckboxes';
import ListColor from '@/components/general/ListColor';
import config from '@/helpers/config';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import { getCarMake, getCarModel } from '../action';
import BrowseVehiclesHeader from '../Header';
import BrowseVehiclesSidebarFilter from './Filter';
import style from './sidebar.module.scss';

const cx = classNames.bind(style);

const BrowseVehiclesSidebar = props => {
  const { filters, metaData, count, onChange, onReset, isSwapVehicle } = props;
  const { colors } = useSelector(state => state?.browseVehiclesReducer);
  const isMobile = useBreakpoint('tablet');
  const dispatch = useDispatch();

  const [startPrice, setStartPrice] = useState(filters?.startPrice || '');
  const [endPrice, setEndPrice] = useState(filters?.endPrice || '');

  const [priceError, setPriceError] = useState({
    budgetFrom: '',
    budgetTo: '',
  });

  const [allCarMake, setAllCarMake] = useState([]);
  const [allCarModel, setAllCarModel] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const [timeoutPrices, setTimeoutPrices] = useState({
    startPrice: () => {},
    endPrice: () => {},
  });
  // const timeoutStartPrice = useRef(null);
  // const timeoutEndPrice = useRef(null);

  const changeStartPrice = val => {
    setPriceError(prevError => ({
      ...prevError,
      budgetFrom: '',
      budgetTo: '',
    }));

    if (Number(val) > Number(endPrice) && (Number(endPrice) > 0 || endPrice !== '')) {
      setPriceError(prevError => ({
        ...prevError,
        budgetFrom: 'This value cannot be higher than To/month',
      }));
      setStartPrice(Number(val) > 0 ? Number(val) : val);
    } else {
      setStartPrice(Number(val) > 0 ? Number(val) : val);
      setTimeoutPrices(prevTimeout => {
        return {
          ...prevTimeout,
          startPrice: setTimeout(() => {
            onChange({
              startPrice: Number(val) > 0 ? Number(val) : val,
              endPrice: Number(endPrice),
            });
          }, 500),
        };
      });
    }

    if (timeoutPrices?.startPrice) {
      clearTimeout(timeoutPrices?.startPrice);
    }
  };

  const changeEndPrice = val => {
    setPriceError(prevError => ({
      ...prevError,
      budgetTo: '',
      budgetFrom: '',
    }));

    if (Number(val) < Number(startPrice) && (Number(startPrice) > 0 || startPrice !== '')) {
      setPriceError(prevError => ({
        ...prevError,
        budgetTo: 'This value cannot be lower than From/month',
      }));
      setEndPrice(Number(val) > 0 ? Number(val) : val);
    } else {
      setEndPrice(Number(val) > 0 ? Number(val) : val);
      setTimeoutPrices(prevTimeout => {
        return {
          ...prevTimeout,
          endPrice: setTimeout(() => {
            onChange({
              endPrice: Number(val) > 0 ? Number(val) : val,
              startPrice: Number(startPrice),
            });
          }, 500),
        };
      });
    }

    if (timeoutPrices?.endPrice) {
      clearTimeout(timeoutPrices?.endPrice);
    }
  };

  const getAllCarModel = ids => {
    const data = { fkCarMakeId: ids };
    dispatch(getCarModel(data)).then(res => {
      if (res?.status && res?.data) {
        const parseCarModel = [];
        res.data.map(make => parseCarModel.push({ value: make.id, label: make.name }));
        setAllCarModel(parseCarModel);
      }
    });
  };

  const getAllCarMake = fkPartnerId => {
    const query = `/${fkPartnerId}`;

    dispatch(getCarMake(query)).then(res => {
      if (res?.status && res?.data) {
        const parseCarMake = [];
        res.data.map(make => parseCarMake.push({ value: make.id, label: make.name }));
        setAllCarMake(parseCarMake);
      }
    });
  };

  const onChangeFilter = (key, id) => {
    if (key === 'ulezCompliant') onChange({ [key]: id === 'yes' ? 'no' : 'yes' });
    else if (key && key.sortOperation) onChange(key);
    else {
      let prev = [...filters[key]];
      if (prev.includes(id)) prev = prev.filter(prevId => id !== prevId);
      else prev.push(id);
      onChange({ [key]: prev });
    }
  };

  // Remove overflow hidden from body
  useEffect(() => {
    return () => {
      if (document.body.classList.contains('overflow-hidden')) {
        document.body.classList.remove('overflow-hidden');
      }
    };
  }, []);

  const toggleShowFilters = () => {
    if (isMobile) {
      if (!showFilters) {
        document.body.classList.add('overflow-hidden');
      } else if (showFilters) {
        document.body.classList.remove('overflow-hidden');
      }
    }
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    // reset price error
    setPriceError({
      budgetFrom: '',
      budgetTo: '',
    });
    onReset();
    setAllCarModel([]);
    toggleShowFilters();
  };

  useEffect(() => {
    if (!filters?.startPrice) setStartPrice('');
    else setStartPrice(filters.startPrice);
    if (!filters?.endPrice) setEndPrice('');
    else setEndPrice(filters.endPrice);
  }, [filters]);

  useEffect(() => {
    if (filters?.carMakeId?.length > 0) getAllCarModel(filters.carMakeId);
    else setAllCarModel([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.carMakeId]);

  useEffect(() => {
    getAllCarMake(config.API.partner_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowseVehiclesHeader
        filters={filters}
        metaData={metaData}
        allCarMake={allCarMake}
        allCarModel={allCarModel}
        colors={colors}
        onChange={onChangeFilter}
        onShowFilters={toggleShowFilters}
        isSwapVehicle={isSwapVehicle}
      />
      <div
        className={cx(style.sidebar, {
          [style.open]: showFilters,
        })}
      >
        <div className={style.header}>
          <div className={style.title}>Filters ({count})</div>
          <LinkIcon onClick={resetFilters}>Reset filters</LinkIcon>
        </div>
        <BrowseVehiclesSidebarFilter label="Monthly budget" collapse={true}>
          <div className={style['row-column']}>
            <div className={style.padding_4}>
              <InputNumberField
                name="budgetFrom"
                placeholder="000"
                label="From/month"
                error={priceError.budgetFrom}
                maxLength={8}
                value={startPrice}
                onChangeHandler={e => changeStartPrice(e.target.value)}
                currency="£"
              />
            </div>
            <div className={style.padding_4}>
              <InputNumberField
                name="budgetTo"
                placeholder="000"
                label="To/month"
                error={priceError.budgetTo}
                maxLength={8}
                value={endPrice}
                onChangeHandler={e => changeEndPrice(e.target.value)}
                currency="£"
              />
            </div>
          </div>
          {/* <div>
            <Checkbox
              label="Include VAT"
              id="vatIncluded"
              name="vatIncluded"
              checked={filters?.isVatIncluded}
              onChange={() => onChange({ isVatIncluded: !filters?.isVatIncluded })}
            />
          </div> */}
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Type">
          <BubbleListing
            data={metaData?.carType ? metaData?.carType : []}
            selectedId={filters?.carTypeId}
            onSelected={id => onChangeFilter('carTypeId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Vehicle Type" collapse={true}>
          <BubbleListing
            data={metaData?.vehicleType ? metaData?.vehicleType : []}
            selectedId={filters?.vehicleTypeId}
            onSelected={id => onChangeFilter('vehicleTypeId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="ULEZ compliant" collapse={false}>
          <div className={style.ulez}>
            <SwitchButton
              id="ulezCompliant"
              name="ulezCompliant"
              status={filters?.ulezCompliant !== 'no' && filters?.ulezCompliant !== null}
              value={filters?.ulezCompliant !== 'no' && filters?.ulezCompliant !== null}
              onChangeHandler={() =>
                onChange({
                  ulezCompliant:
                    filters?.ulezCompliant === 'no' || filters?.ulezCompliant === null
                      ? 'yes'
                      : 'no',
                })
              }
            />
          </div>
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Make" collapse={true}>
          <SearchCheckboxes
            options={allCarMake}
            selected={filters?.carMakeId}
            onChange={id => onChangeFilter('carMakeId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter
          label="Model"
          collapse={true}
          disabled={filters?.carMakeId?.length === 0}
        >
          <SearchCheckboxes
            options={allCarModel}
            selected={filters?.carModelId}
            onChange={id => onChangeFilter('carModelId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Colour" collapse={true} disabled={colors?.length === 0}>
          <ListColor
            options={colors}
            selected={filters?.selectedColor}
            onChange={id => onChangeFilter('selectedColor', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Fuel Type" collapse={true}>
          <BubbleListing
            data={metaData?.fuelType ? metaData?.fuelType : []}
            selectedId={filters?.fuelTypeId}
            onSelected={id => onChangeFilter('fuelTypeId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Number of the doors">
          <BubbleListing
            data={metaData?.doorType ? metaData?.doorType : []}
            selectedId={filters?.doorId}
            onSelected={id => onChangeFilter('doorId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        <BrowseVehiclesSidebarFilter label="Gearbox Type">
          <BubbleListing
            data={metaData?.gearbox ? metaData?.gearbox : []}
            selectedId={filters?.gearboxId}
            onSelected={id => onChangeFilter('gearboxId', id)}
          />
        </BrowseVehiclesSidebarFilter>
        {showFilters && (
          <div className={style['view-button']}>
            <Button onClick={toggleShowFilters}>View results</Button>
          </div>
        )}
      </div>
      <div
        className={cx(style.sidebar_bg, {
          [style.open]: showFilters,
        })}
      ></div>
    </>
  );
};

export default BrowseVehiclesSidebar;

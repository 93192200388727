import React, { useEffect, useState } from 'react';
import Checkbox from '../Checkbox';
import style from './search-checkboxes.module.scss';

const SearchCheckboxes = props => {
  const { options, selected, onChange } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [localOptions, setOptions] = useState(options);
  const [showMore, setShowMore] = useState(false);

  const isSelected = id => {
    return selected?.includes(id);
  };

  useEffect(() => {
    if (searchTerm !== '')
      setOptions(
        options.filter(
          option => isSelected(option.value) || option.label.match(new RegExp(searchTerm, 'i')),
        ),
      );
    else setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setOptions(options);
    if (options && options?.length > 5) setShowMore(true);
  }, [options]);

  return (
    <div className={style['search-checkboxes']}>
      <div>
        <input
          className={style.search}
          type="text"
          value={searchTerm}
          placeholder="Search"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className={style.options}>
        {localOptions?.map((option, index) =>
          (showMore && index < 5) || !showMore ? (
            <Checkbox
              key={index}
              id={option.value}
              name={option.label}
              label={option.label}
              checked={isSelected(option?.value)}
              onChange={() => onChange(option?.value)}
            />
          ) : null,
        )}
        {localOptions?.length > 5 ? (
          <p className={style.showMore} onClick={() => setShowMore(prevState => !prevState)}>
            {showMore ? 'Show more' : 'Show less'}
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SearchCheckboxes;

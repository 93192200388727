import React from 'react';
import VehicleItem from '@/components/general/VehicleItem';
import { SkeletonVehicleItem } from '@/components/general';
import CurrentVehicle from '@/flow/SwapVehicle/CurrentVehicle';
import style from './list.module.scss';

const BrowseVehiclesList = props => {
  const { vehicles, filters, currentSubscriptionData, isSwapVehicle, scrollLoader } = props;
  return (
    <>
      <div className={style.list}>
        {currentSubscriptionData && (
          <CurrentVehicle data={currentSubscriptionData} isSwapVehicle={isSwapVehicle} />
        )}
        {scrollLoader ? (
          <>
            <SkeletonVehicleItem />
          </>
        ) : (
          <>
            {vehicles.map(vehicle => (
              <VehicleItem
                key={vehicle.id}
                data={vehicle}
                isVatIncluded={filters?.isVatIncluded}
                isSwapRoute={isSwapVehicle}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default BrowseVehiclesList;

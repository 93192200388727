import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { toastError } from '@/components/general/ToastNotifications/toastType';
import CONFIG from '@/helpers/config';
import { load, save } from '@/helpers/cookies'; // TODO
import { browseSuggestedVehicles, browseVehicles, getColors, getMetaData } from './action';
import BrowseVehiclesList from './List';
import BrowseVehiclesSidebar from './Sidebar';
import LISTINGS_TYPE from './action-type';
import style from './browse-vehicles.module.scss';

const cx = classNames.bind(style);

const BrowseVehiclesContent = props => {
  const { vehicles, count: totalCount, currentSubscriptionData, isSwapVehicle } = props;
  const dispatch = useDispatch();
  const { metaData } = useSelector(state => state?.browseVehiclesReducer);
  const [vehiclesData, setVehicles] = useState(vehicles || []);
  const [count, setCount] = useState(totalCount || 0);
  const [suggested, setSuggested] = useState(false);
  const defaultLimit = CONFIG.BROWSE_VEHICLE_DEFAULT_LIMIT;
  const browseVehicleRef = useRef({ count: 0, page: 1, limit: defaultLimit });
  const [callComplete, setComplete] = useState(true);
  const [scrollLoader, setScrollLoader] = useState(true);
  const vehicleRef = useRef(null);
  const initValues = {
    fkPartnerId: CONFIG.API.partner_id,
    vehicleTypeId: [],
    carTypeId: [],
    fuelTypeId: [],
    doorId: [],
    gearboxId: [],
    carMakeId: [],
    carModelId: [],
    startPrice: null,
    endPrice: null,
    isVatIncluded: true,
    ulezCompliant: 'no',
    sortCol: 'pricing',
    sortOperation: 'ASC',
    selectedColor: [],
    lifestyleTag: false,
  };

  const [filters, setFilters] = useReducer(
    (prev, updates) => ({ ...prev, ...updates }),
    initValues,
  );

  const onScrollToSelectedVehicle = () => {
    if (typeof window !== 'undefined') {
      const scrollVehicleId = localStorage.getItem('selectedVehicle');
      const selectedVehicle = document.getElementById(scrollVehicleId);
      if (selectedVehicle) {
        vehicleRef.current = selectedVehicle;
        if (vehicleRef.current) {
          vehicleRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
        localStorage.removeItem('selectedVehicle');
      } else if (vehiclesData?.length === 0) {
        window.scrollTo(0, 0);
      }
    }
  };

  const getSuggestedVehicleList = () => {
    const data = '/0';
    setSuggested(true);
    dispatch(browseSuggestedVehicles(data, CONFIG.API.partner_id)).then(res => {
      if (res?.status && res?.data) {
        if (res?.data?.length > 0) setVehicles(res?.data);
        setScrollLoader(false);
      }
    });
  };

  const getVehiclesByColorFilter = ({ data, vehiclesList }) => {
    const vehicleByColorFilter = vehiclesList.map(vehicle => {
      if (vehicle?.newImageArr?.length > 0) {
        const matchImgToSelectedColorFilter = vehicle.newImageArr.filter(
          imageArr => imageArr[0] === data?.color[0],
        );

        const updatedImgUrl =
          matchImgToSelectedColorFilter?.length > 0
            ? matchImgToSelectedColorFilter[0][1]
            : vehicle.imageUrl;

        return { ...vehicle, imageUrl: updatedImgUrl };
      }
      return vehicle;
    });
    return vehicleByColorFilter;
  };

  const getFilteredVehicleList = () => {
    const excludeListingId = currentSubscriptionData
      ? { excludeListingId: currentSubscriptionData?.id }
      : {};
    const data = {
      ...filters,
      ...excludeListingId,
      page: browseVehicleRef.current.page,
      limit: browseVehicleRef.current.limit,
    };
    if (data.vehicleTypeId?.length === 0) delete data.vehicleTypeId;
    if (data.carTypeId?.length === 0) delete data.carTypeId;
    if (data.fuelTypeId?.length === 0) delete data.fuelTypeId;
    if (data.gearboxId?.length === 0) delete data.gearboxId;
    if (data.carMakeId?.length === 0) delete data.carMakeId;
    if (data.carModelId?.length === 0) delete data.carModelId;
    if (data.doorId?.length === 0) delete data.doorId;
    if (!data.startPrice) delete data.startPrice;
    if (!data.endPrice) delete data.endPrice;
    if (!data.ulezCompliant || data.ulezCompliant === 'no') delete data.ulezCompliant;
    if (!data.sortCol) delete data.sortCol;
    if (!data.sortOperation) delete data.sortOperation;
    if (data.selectedColor?.length) {
      data.color = data.selectedColor;
      delete data.selectedColor;
    } else delete data.selectedColor;
    if (Object.prototype.hasOwnProperty.call(data, 'lifestyleTag')) delete data.lifestyleTag;
    if (vehiclesData <= defaultLimit) setScrollLoader(true);
    dispatch(browseVehicles(data)).then(res => {
      if (res?.status && res?.data) {
        if (res?.data?.list?.length > 0) {
          setVehicles(getVehiclesByColorFilter({ data, vehiclesList: res.data.list }));
          browseVehicleRef.current.count = res.data.count;
          setScrollLoader(false);
          setComplete(true);
          onScrollToSelectedVehicle();
        } else {
          getSuggestedVehicleList();
        }
      }
    });
  };

  const getColorsData = async () => {
    const res = await getColors();
    if (res?.status && res?.data?.length > 0) {
      await dispatch({ type: LISTINGS_TYPE.COLOR, payload: res.data });
    } else {
      toastError({ message: res?.message });
    }
  };

  const countActiveFilters = () => {
    setSuggested(false);
    const diffArr = Object.keys(initValues).reduce((diff, key) => {
      if (initValues[key] === filters[key]) return diff;
      return {
        ...diff,
        [key]: filters[key],
      };
    }, {});
    let curr = 0;
    // if (initValues.isVatIncluded !== filters.isVatIncluded) curr += 1;
    if (filters.startPrice && filters.endPrice) curr += 1;
    // if(filters.endPrice) curr += 1;
    Object.keys(diffArr).forEach(key => {
      if (diffArr[key]?.length > 0) curr += 1;
    });
    setCount(curr);
  };

  const onChangeFilters = obj => {
    if (window) {
      localStorage.removeItem('selectedVehicle');
    }
    setFilters(obj);
  };

  const onResetFilters = () => {
    if (window) {
      localStorage.removeItem('selectedVehicle');
    }
    save(CONFIG.COOKIE_BROWSE_FILTERS, {});
    setFilters(initValues);
  };

  const handleInfiniteScroll = async e => {
    if (e?.target?.scrollingElement) {
      const { scrollTop, scrollHeight, offsetHeight } = e.target.scrollingElement;
      const { count: vehicleCount, page, limit } = browseVehicleRef.current;
      const maxApiCall = Math.ceil(vehicleCount / limit);
      const scrollPosition = scrollTop;
      const maxHeight = scrollHeight;
      const containerOffSet = offsetHeight + 1000;
      if (vehicleCount !== vehiclesData?.length) {
        if (scrollPosition + containerOffSet > maxHeight && page < maxApiCall && callComplete) {
          browseVehicleRef.current.limit = limit + defaultLimit;
          setComplete(false);
          getFilteredVehicleList();
        }
      }
    }
  };

  useEffect(() => {
    getColorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters !== initValues) {
      save(CONFIG.COOKIE_BROWSE_FILTERS, filters);
      if (isSwapVehicle) {
        if (currentSubscriptionData) getFilteredVehicleList();
      } else {
        getFilteredVehicleList();
      }
    }
    countActiveFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscriptionData, filters]);

  useEffect(() => {
    dispatch(getMetaData());
    const cookieFilters = load(CONFIG.COOKIE_BROWSE_FILTERS);
    if (props.filters) {
      setFilters(props.filters);
    } else if (cookieFilters && !cookieFilters.lifestyleTag) {
      setFilters(cookieFilters);
    } else {
      setFilters(initValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onScroll = e => {
      handleInfiniteScroll(e);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <div className={style.page}>
      <div className={cx(style.container, { [style.swapVehicle]: isSwapVehicle })}>
        <div
          className={cx(style.list, {
            [style.tags]: count,
          })}
        >
          {suggested && (
            <div className={style.suggested}>
              <h1>Really sorry!</h1>
              <p>
                We do not have a vehicle that matches that criteria, try altering your search or
                here are some alternatives you might like...
              </p>
            </div>
          )}
          <BrowseVehiclesList
            vehicles={vehiclesData}
            metaData={metaData}
            filters={filters}
            currentSubscriptionData={currentSubscriptionData}
            isSwapVehicle={isSwapVehicle}
            scrollLoader={scrollLoader}
          />
        </div>
        <BrowseVehiclesSidebar
          metaData={metaData}
          filters={filters}
          count={count}
          onChange={onChangeFilters}
          onReset={onResetFilters}
          isSwapVehicle={isSwapVehicle}
        />
      </div>
    </div>
  );
};

export default BrowseVehiclesContent;
